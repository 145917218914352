import { Key, useContext, useEffect, useRef, useState } from 'react'
import { fetchUserByID, fetchProfessionalsFromShop } from '../api/booking'
import { auth } from '../config/firebase.config'
import { useIsMobile } from '../hooks/useIsMobile'
import { SplashLoader } from '../lotties'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '../store/models/userModel'
import moment from 'moment'
import AppointmentTable from './AppointmentTable'
import * as Icon from 'react-feather';
import { usePostHog } from 'posthog-js/react'

import { Oval } from 'react-loader-spinner'

import toast, { Toaster } from 'react-hot-toast'
import AppointmentDetails from './AppointmentDetails'
import AppointmentItemMobile from './AppointmentItemMobile'
import { AppPageSelector } from '../store/models/appPage'
import { AppointmentSelector } from '../store/models/appointmentsModel'
import { shopSelector } from '../store/models/shopModel'
import { professionalSelector } from '../store/models/professionalsModel'
import TextInput from './input/TextInput'
import FilterChip from './FilterChip'
import { analyticsEvents } from '../utils/Events'
import { serviceSelector } from '../store/models/Services'
import { useNavigate } from 'react-router'
import EmptyStateNotice from './EmptyStateNotice'
import { canAddService, canSeeAllAppointments } from '../utils/roles'
import { Button } from './button'

enum PageParts {
    SIDEBAR = 'sidebar',
    BOOKINGS = 'bookings',
    APPOINTMENT_DETAILS = 'appointments',
}

interface IAppointmentsPage {
    data: any,
    title: string,
    listTitle: string,
    type: "upcoming" | "history",
}


const AppointmentsPage = ({ data, title, listTitle, type }: IAppointmentsPage) => {

    const { selectedShop } = useSelector(shopSelector)
    const { proNames, selectedProIndex, proIDs } = useSelector(professionalSelector)


    const shop = useSelector(shopSelector)
    const services = useSelector(serviceSelector)
    // const pros = useSelector(professionalSelector)

    const [servicesCount, setServicesCount] = useState<number>(0)
    const [prosCount, setprosCount] = useState<number>(0)

    const navigate = useNavigate()

    useEffect(() => {

        setServicesCount(Object.keys(services)?.length)
        setprosCount(proIDs[shop.selectedShop]?.length)

    }, [services, proIDs, shop.selectedShop, servicesCount, prosCount])

    const showToast = (text: string, type: 'success' | 'error', duration?: number) => {
        if (type === 'success') {
            toast.success(text, {
                duration: duration
            })
        } else if (type === 'error') {
            toast.error(text, {
                duration: duration
            })
        }
    }
    const dispatch = useDispatch()

    const { businesses, businessID, roles, name } =
        useSelector(userSelector)

    const { isMobile } = useIsMobile()

    const { page, pagePart } = useSelector(AppPageSelector)
    const [selectedBooking, setSelectedBooking] = useState<any>([])

    const selectedBookingIndexRef = useRef<any>()

    const { upcoming, history, loadingAppointments, proAppointmentLoading }: any = useSelector(AppointmentSelector)


    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const [overviewPeriod, setOverviewPeriod] = useState('week')

    const [professionalsIDs, setprofessionalsIDs] = useState<string[]>([])
    const [professionalsNames, setprofessionalsNames] = useState<string[]>([])

    const [filter, setFilter] = useState<string | undefined>("")

    const filterRef = useRef<any>()

    // set the first booking in the list as the one that is displayed
    useEffect(() => {
        if (data) {
            setSelectedBooking(data?.[0])
        }
    }, [data])

    useEffect(() => {
        if (error !== '') {
            setTimeout(() => {
                setError('')
            }, 5000)
        }
    }, [error])

    // fetch the pros names
    useEffect(() => {
        const shopPros = async () => {
            const professionals: string[] = []
            const profesionalsIDs = await fetchProfessionalsFromShop({ queryKey: ['shopID', selectedShop] })
            setprofessionalsIDs(proIDs)

            profesionalsIDs?.map(async (pro: string) => {
                const professional = await fetchUserByID({ queryKey: ['proID', pro] })
                professionals.push(professional?.name)

            })
            setprofessionalsNames(professionals)
        }
        if (selectedShop) {
            shopPros()
        }

    }, [businessID, businesses, selectedShop, page, pagePart])


    const posthog = usePostHog()

    const completedChipRef = useRef<any>()
    const cancelledChipRef = useRef<any>()
    const didntShowChipRef = useRef<any>()
    const rejectedChipRef = useRef<any>()
    const acceptedChipRef = useRef<any>()
    const pendingChipRef = useRef<any>()

    const [numberOfEntries, setNumberOfEntries] = useState<number>(0)
    if (selectedBookingIndexRef?.current) {
        setTimeout(() => setNumberOfEntries(selectedBookingIndexRef?.current?.getNumberOfEntries()), 100)
    }


    if (loading) {
        return <SplashLoader />
    }

    if (error) {
        return <div>Error! {(error as any).message}</div>
    }

    const logout = () => {
        auth.signOut()
        dispatch.User.saveUser({})
        navigate("/signin");

        analyticsEvents.logout();
        posthog.capture("Logout")
    }

    const handlePageUpdate = (part: PageParts) => {
        // setPageParts(part)
        if (isMobile) {
            dispatch.AppPage.setPagePart(part)
        }

    }

    return (
        <div className="h-screen bg-gray-50">

            <main className="flex">
                {/* Main body */}
                <div className='flex flex-col w-full'>
                    <div className='flex'>
                        {(pagePart === PageParts.APPOINTMENT_DETAILS || !isMobile) && (

                            <div className="w-full px-6 pt-4 text-black md:hidden bg-white">
                                <div className="w-full shrink-0 md:block ">
                                    <AppointmentDetails selectedBooking={selectedBooking} activePage={type} isMobile={isMobile}
                                        onCheckout={() => {
                                            handlePageUpdate(PageParts.BOOKINGS)
                                            showToast("Appointment marked as completed", "success")
                                            analyticsEvents.checkout()
                                            posthog.capture("Checkout")
                                        }}
                                        onDidntShow={() => {
                                            handlePageUpdate(PageParts.BOOKINGS)
                                            showToast("Appointment marked as didn't show", "success")
                                            analyticsEvents.noShow()
                                            posthog.capture("No show")
                                        }}
                                        onAccept={() => {
                                            handlePageUpdate(PageParts.BOOKINGS)
                                            showToast("Appointment accepted!", "success")
                                            analyticsEvents.acceptAppointment()
                                            posthog.capture("Accepted appointment")
                                        }}
                                        onReject={() => {
                                            handlePageUpdate(PageParts.BOOKINGS)
                                            showToast("Appointment rejected", "success")
                                            analyticsEvents.rejectAppointment()
                                            posthog.capture("Rejected appointment")
                                        }}
                                        onActionError={() => {
                                            handlePageUpdate(PageParts.BOOKINGS)
                                            showToast("Whoops, we couldn't do that. Reload the page and try again! If the error persists, get in touch with the Wavier Team ASAP", "error", 10000)
                                        }}
                                    />
                                </div>

                            </div>


                        )}

                        {(pagePart === PageParts.BOOKINGS || !isMobile) && (
                            // Booking List
                            <div className="w-full h-screen pt-4 border-gray-100 flex flex-col ">
                                <div className="py-2 px-6">
                                    <p className={`mb-2 font-semibold leading-6 ${isMobile ? "text-xl" : "text-2xl"}`}>
                                        {title}
                                    </p>
                                </div>
                                {loadingAppointments ?
                                    <div className='flex w-full justify-center'>
                                        <Oval color='#343434' secondaryColor='#7D7D7D' width={40} />
                                    </div>
                                    :
                                    <div className='relative flex-1 flex flex-col overflow-auto'>
                                        {/* <div className='bg-white mx-2 mt-2 py-2 px-4 rounded-lg'>
                                            <div className='flex justify-between'>
                                                <div>
                                                    <div className='mb-2 font-medium text-md'>
                                                        {`Overview - ${proNames[selectedShop]?.[selectedProIndex] ? proNames[selectedShop]?.[selectedProIndex] : ""} (${overviewPeriod === 'today' ? 'today' : 'this week'})`}
                                                    </div>
                                                    <div className='flex'>
                                                        <div className='bg-gray-50 rounded-lg py-2 px-4 mr-2'>
                                                            <div className='flex justify-between'>
                                                                <div className='text-xl font-bold '>
                                                                    {upcoming?.filter((apts: any) => {
                                                                        if (overviewPeriod === 'today') {
                                                                            
                                                                            return moment(apts.isoDate).format("DDMMYYY") === moment().format("DDMMYYY")
                                                                        } else {
                                                                            return moment(apts.isoDate).isSameOrAfter(moment().startOf('week').format("DDMMYYY"))
                                                                                && moment(apts.isoDate).isSameOrBefore(moment().endOf('week').format("DDMMYYY"))
                                                                        }
                                                                    }).length}
                                                                </div>
                                                                <div className='flex items-center'>
                                                                    <Icon.Calendar size={20} color='#2e90fa' />
                                                                </div>
                                                            </div>
                                                            <div className='text-sm'>
                                                                Upcoming
                                                            </div>
                                                        </div>
                                                        <div className='bg-gray-50 rounded-lg py-2 px-4 mr-2 '>
                                                            <div className='flex justify-between'>
                                                                <div className='text-xl font-bold '>

                                                                    {history?.filter((apts: any) => {
                                                                        if (overviewPeriod === 'today') {
                                                                            return moment(apts.isoDate).format("DDMMYYYY") === moment().format("DDMMYYYY") && apts.status === 'completed'
                                                                        } else {
                                                                            return (moment(apts.isoDate).isSameOrAfter(moment().startOf('week')) && (moment(apts.isoDate).isSameOrBefore(moment().endOf('week'))) && (apts.status === 'completed'))
                                                                        }
                                                                    }).length}
                                                                </div>
                                                                <div className='flex items-center'>
                                                                    <Icon.Check color='#12b76a' />
                                                                </div>
                                                            </div>
                                                            <div className='text-sm'>
                                                                Completed
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex flex-col items-end'>
                                                    <div className={`flex h-8 gap-2 ${isMobile && 'flex-col'}`}>
                                                        <div className={`transition text-xs px-4 py-2 border ${overviewPeriod === 'today' ? 'border-gray-700 border-2 font-semibold text-gray-700' : 'text-gray-500'} rounded-lg flex items-center cursor-pointer justify-center`}
                                                            onClick={() => {
                                                                setOverviewPeriod('today')
                                                            }}>
                                                            Today
                                                        </div>
                                                        <div className={`transition text-xs px-4 py-2 border ${overviewPeriod === 'week' ? 'border-gray-700 border-2 font-semibold text-gray-700' : 'text-gray-500'} rounded-lg flex items-center cursor-pointer justify-center`}
                                                            onClick={() => {
                                                                setOverviewPeriod('week')
                                                            }}>
                                                            This week
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {!isMobile && <div className='text-xs text-gray-600 mt-3 flex justify-end'>
                                                            {overviewPeriod === 'today' ?
                                                                <>
                                                                    <div>
                                                                        {`${moment().format("dddd, Do MMMM YYYY")}`}
                                                                    </div>
                                                                </>
                                                                :
                                                                <div className='flex flex-col justify-start items-end'>
                                                                   
                                                                    <div>
                                                                        {`From ${moment().startOf('week').format("dddd, Do MMMM YYYY")} `}
                                                                    </div>
                                                            
                                                                    <div>
                                                                        {`to ${moment().endOf('week').format("dddd, Do MMMM YYYY")} `}
                                                                    </div>
                                                                </div>}
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className=" flex flex-col flex-1 overflow-auto">
                                            <>
                                                <div className='flex-1 flex flex-col '>
                                                    {data ? (
                                                        <div className='flex-1 flex '>
                                                            {isMobile ?
                                                                <>
                                                                    <div className='bg-white rounded-lg w-full mx-2 my-2'>
                                                                        <div className='font-medium text-md py-2 px-4'>
                                                                            {listTitle}
                                                                        </div>
                                                                        {proNames && (
                                                                            <div className='px-4'>

                                                                                {proNames.length === 0 ?
                                                                                    <>
                                                                                        <div className='flex w-full justify-center'>
                                                                                            <Oval color='#343434' secondaryColor='#7D7D7D' width={40} />
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <div className='flex flex-row justify-between items-center gap-2'>
                                                                                        {canSeeAllAppointments(roles) ?
                                                                                            <>
                                                                                                <div className='text-xs text-gray-500'>
                                                                                                    Select a team member
                                                                                                </div>
                                                                                                <div className='flex gap-2 flex-wrap w-full  py-2'>
                                                                                                    {proNames && proNames[selectedShop]?.map((pro: any, index: number) => {
                                                                                                        return (
                                                                                                            <div key={index}>
                                                                                                                <div className={`transition px-4 py-2 border rounded-lg flex items-center justify-center text-center text-sm cursor-pointer border-gray-100 ${selectedProIndex === index ? "bg-gray-100" : "bg-white"}`}
                                                                                                                    onClick={() => {
                                                                                                                        if (selectedProIndex !== index) {
                                                                                                                            dispatch.Professionals.setSelectedPro(proIDs[shop.selectedShop][index])
                                                                                                                            dispatch.Professionals.selectedProIndex(index)
                                                                                                                            dispatch.AppointmentModel.setProAppointmentLoading(true)
                                                                                                                        }
                                                                                                                     
                                                                                                                    }}>
                                                                                                                    {pro}
                                                                                                                </div>
                                                                                                            </div>)
                                                                                                    })}
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <>

                                                                                                <div className='flex gap-2 flex-wrap w-full  py-2'>
                                                                                                    <div className={`transition px-4 py-2 border rounded-lg flex items-center justify-center text-center text-sm cursor-pointer border-gray-100 bg-gray-100 `}>
                                                                                                        {name}

                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        }



                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        <div className='pt-2 pr-2'>
                                                                            <Button disabled={false} onClick={() => {
                                                                                dispatch.Modals.toggleAddAppointmentModal(true);
                                                                            }} size={'sm'} >
                                                                                <div className="flex flex-row gap-2 justify-center items-center">
                                                                                    <Icon.Plus size={16} />
                                                                                    <p>Add appointment</p>
                                                                                </div>

                                                                            </Button>
                                                                        </div>
                                                                        <div className='flex-1 bg-white rounded-lg overflow-auto '>
                                                                            {proAppointmentLoading ?
                                                                                <>
                                                                                    <div className='flex w-full justify-center'>
                                                                                        <Oval color='#343434' secondaryColor='#7D7D7D' width={40} />
                                                                                    </div>
                                                                                </> :
                                                                                <>
                                                                                    {data?.length === 0 ?
                                                                                        (
                                                                                            <>
                                                                                                {servicesCount === 0 ?
                                                                                                    <>
                                                                                                        <EmptyStateNotice icon='x'
                                                                                                            headline='You have not added any service yet'
                                                                                                            body='Add services to let your customers book the ones they like. Your appointments will appear here'
                                                                                                            action='Add a service'
                                                                                                            showAction={canAddService(roles)}
                                                                                                            onPressAction={() => {
                                                                                                                navigate("/app/settings/services?action=add")
                                                                                                            }}
                                                                                                        />
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <div className="w-96 flex flex-col flex-1 justify-center items-center mx-auto py-4">
                                                                                                            <div className='border-8 w-16 h-16 flex justify-center items-center bg-primary-100 border-primary-50 rounded-full mb-5'>
                                                                                                                <Icon.XCircle size={30} />
                                                                                                            </div>
                                                                                                            {type === "upcoming" ?
                                                                                                                <>
                                                                                                                    <p className="mb-2 text-md leading-6 font-semibold ">There is no upcoming appointments...yet</p>
                                                                                                                    <p className="mb-2 text-sm leading-6 text-gray-500 ">New appointments will appear here</p>
                                                                                                                </> :
                                                                                                                <>
                                                                                                                    <p className="mb-2 text-md leading-6 font-semibold ">History is empty</p>
                                                                                                                    <p className="mb-2 text-sm leading-6 text-gray-500 ">Complete an appointment and it'll show up here!</p>
                                                                                                                </>}
                                                                                                        </div>
                                                                                                    </>}
                                                                                            </>

                                                                                        ) : (
                                                                                            <><div className='w-full flex flex-col justify-between py-2 px-4'>
                                                                                                <div className='w-full py-2'>
                                                                                                    <TextInput ref={filterRef} placeholder='Search customers' onChange={() => {
                                                                                                        setFilter(filterRef.current.getInputText())
                                                                                                        if (type === "upcoming") {
                                                                                                            acceptedChipRef.current.deselectChip()
                                                                                                            pendingChipRef.current.deselectChip()
                                                                                                        } else {
                                                                                                            completedChipRef.current.deselectChip()
                                                                                                            cancelledChipRef.current.deselectChip()
                                                                                                            didntShowChipRef.current.deselectChip()
                                                                                                        }
                                                                                                    }}
                                                                                                        leadingIcon={<Icon.Search color='#d0d5dd' size={20} />}
                                                                                                        trailingIcon={
                                                                                                            <div className='transition w-5 h-5 rounded-full bg-gray-100 hover:bg-gray-200 flex justify-center items-center'>
                                                                                                                <Icon.X color='#afb5d9' size={12} />
                                                                                                            </div>}
                                                                                                        onTrailingClick={() => {
                                                                                                            setFilter("")
                                                                                                            filterRef?.current.clearValue()
                                                                                                        }} />
                                                                                                </div>

                                                                                            </div>
                                                                                                <div className='w-full'>
                                                                                                    {data?.filter((appointment: any) => { //filter customer name
                                                                                                        if (filterRef?.current) {
                                                                                                            if (filterRef?.current.getInputText() !== "") {
                                                                                                                return appointment.user.name.toLowerCase().includes(filterRef?.current.getInputText().toLowerCase())
                                                                                                            } else {
                                                                                                                return appointment
                                                                                                            }
                                                                                                        }
                                                                                                    }).map(
                                                                                                        (item: {
                                                                                                            id: Key | null | undefined
                                                                                                            isoDate: string
                                                                                                            endTime: string
                                                                                                            Service_Cost: string
                                                                                                            user: {
                                                                                                                name: any
                                                                                                                profilePic: any
                                                                                                            }
                                                                                                            services: any
                                                                                                            status: string
                                                                                                        }, index: number) => {
                                                                                                            // console.log("item", item)
                                                                                                            return (
                                                                                                                <div key={item.id}>
                                                                                                                    <AppointmentItemMobile

                                                                                                                        client={item.user?.name}
                                                                                                                        startTime={item.isoDate}
                                                                                                                        endTime={item.endTime}
                                                                                                                        service={item.services ? item.services[0]?.Name : "[None]"}
                                                                                                                        status={item.status}
                                                                                                                        onClick={() => {
                                                                                                                            setSelectedBooking(data[index])
                                                                                                                            handlePageUpdate(
                                                                                                                                PageParts.APPOINTMENT_DETAILS
                                                                                                                            )
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            )
                                                                                                        }
                                                                                                    )

                                                                                                    }
                                                                                                </div>
                                                                                            </>
                                                                                        )}
                                                                                </>
                                                                            }


                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className='flex-1 bg-white rounded-lg m-2 ' onClick={() => {
                                                                        setTimeout(() => {
                                                                            //wait a little for state to change, that's a wildcard
                                                                            selectedBookingIndexRef.current && setSelectedBooking(data[selectedBookingIndexRef.current.getSelectedBookingIndex()])
                                                                            handlePageUpdate(
                                                                                PageParts.APPOINTMENT_DETAILS
                                                                            )
                                                                        }, 100)

                                                                    }}>
                                                                        <div >
                                                                            <div className="flex flex-row justify-between px-4 pt-2">
                                                                                <div className='font-medium text-md  flex-1 '>
                                                                                    {listTitle}
                                                                                </div>
                                                                                <div className='pt-2'>
                                                                                    <Button disabled={false} onClick={() => {
                                                                                        dispatch.Modals.toggleAddAppointmentModal(true);
                                                                                    }} size={'sm'} hug>
                                                                                        <div className="flex flex-row gap-2 justify-between items-center">
                                                                                            <Icon.Plus size={16} />
                                                                                            <p>Add appointment</p>
                                                                                        </div>

                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                            {(<div className='px-4'>

                                                                                <div className='flex gap-2 flex-wrap w-full py-2'>

                                                                                    {canSeeAllAppointments(roles) ?
                                                                                        <>
                                                                                            <div className='text-xs text-gray-500'>
                                                                                                Select a team member
                                                                                            </div>
                                                                                            <div className='flex gap-2 flex-wrap w-full  py-2'>
                                                                                                {proNames && proNames[selectedShop]?.map((pro: any, index: number) => {
                                                                                                    return (
                                                                                                        <div key={index}>
                                                                                                            <div className={`transition px-4 py-2 border rounded-lg flex items-center justify-center text-center text-sm cursor-pointer border-gray-100 ${selectedProIndex === index ? "bg-gray-100" : "bg-white"}`}
                                                                                                                onClick={() => {
                                                                                                                    if (selectedProIndex !== index) {
                                                                                                                        dispatch.Professionals.setSelectedPro(proIDs[shop.selectedShop][index])
                                                                                                                        dispatch.Professionals.selectedProIndex(index)
                                                                                                                        dispatch.AppointmentModel.setProAppointmentLoading(true)
                                                                                                                       
                                                                                                                    }
                                                                                                                }}>
                                                                                                                {pro}
                                                                                                            </div>
                                                                                                        </div>)
                                                                                                })}
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        <>

                                                                                            <div className='flex gap-2 flex-wrap w-full  py-2'>
                                                                                                <div className={`transition px-4 py-2 border rounded-lg flex items-center justify-center text-center text-sm cursor-pointer border-gray-100 bg-gray-100 `}>
                                                                                                    {name}

                                                                                                </div>
                                                                                            </div>
                                                                                        </>}

                                                                                </div>

                                                                            </div>)}
                                                                            {proAppointmentLoading ?
                                                                                <>
                                                                                    <div className='flex w-full justify-center'>
                                                                                        <Oval color='#343434' secondaryColor='#7D7D7D' width={40} />
                                                                                    </div>
                                                                                </> :
                                                                                <>
                                                                                    {data?.length === 0 ?
                                                                                        (
                                                                                            <>
                                                                                                {servicesCount === 0 ?
                                                                                                    <>
                                                                                                        <EmptyStateNotice icon='x'
                                                                                                            headline='You have not added any service yet'
                                                                                                            body='Add services to let your customers book the ones they like. Your appointments will appear here'
                                                                                                            action='Add a service'
                                                                                                            showAction={canAddService(roles)}
                                                                                                            onPressAction={() => {
                                                                                                                navigate("/app/settings/services?action=add")
                                                                                                            }}
                                                                                                        />
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <div className="w-96 flex flex-col flex-1 justify-center items-center mx-auto py-4">
                                                                                                            <div className='border-8 w-16 h-16 flex justify-center items-center bg-primary-100 border-primary-50 rounded-full mb-5'>
                                                                                                                <Icon.XCircle size={30} />
                                                                                                            </div>
                                                                                                            {type === "upcoming" ?
                                                                                                                <>
                                                                                                                    <p className="mb-2 text-md leading-6 font-semibold ">You have no upcoming appointments...yet</p>
                                                                                                                    <p className="mb-2 text-sm leading-6 text-gray-500 ">Your new appointments will appear here</p>
                                                                                                                </> :
                                                                                                                <>
                                                                                                                    <p className="mb-2 text-md leading-6 font-semibold ">Your history is empty</p>
                                                                                                                    <p className="mb-2 text-sm leading-6 text-gray-500 ">Complete an appointment and it'll show up here!</p>
                                                                                                                </>}
                                                                                                        </div>
                                                                                                    </>}
                                                                                            </>

                                                                                        ) : (

                                                                                            <>
                                                                                                <div className='text-sm text-gray-600 py-2 px-4'>
                                                                                                    {`${numberOfEntries} ${numberOfEntries === 1 ? "entry" : "entries"}`}
                                                                                                </div>
                                                                                                <div className='w-full flex justify-between pb-2 px-4'>

                                                                                                    {type === "upcoming" ?
                                                                                                        <>
                                                                                                            <div className='flex items-center justify-start gap-2'>
                                                                                                                <div className='text-sm text-gray-500'>
                                                                                                                    Filter by:
                                                                                                                </div>
                                                                                                                <FilterChip ref={acceptedChipRef} onSelect={() => {
                                                                                                                    setTimeout(() => setFilter(acceptedChipRef.current.getFilterChipSelected()), 100)
                                                                                                                    filterRef.current.clearValue()
                                                                                                                    pendingChipRef.current.deselectChip()
                                                                                                                }}>Accepted</FilterChip>
                                                                                                                <FilterChip ref={pendingChipRef} onSelect={() => {
                                                                                                                    setTimeout(() => setFilter(pendingChipRef.current.getFilterChipSelected()), 100)
                                                                                                                    filterRef.current.clearValue()
                                                                                                                    acceptedChipRef.current.deselectChip()
                                                                                                                }}>Pending</FilterChip>
                                                                                                            </div>
                                                                                                        </> :
                                                                                                        <>
                                                                                                            <div className='flex items-center justify-start gap-2'>
                                                                                                                <div className='text-sm text-gray-500'>
                                                                                                                    Filter by:
                                                                                                                </div>
                                                                                                                <FilterChip ref={completedChipRef} onSelect={() => {
                                                                                                                    setTimeout(() => setFilter(completedChipRef.current.getFilterChipSelected()), 100)
                                                                                                                    filterRef.current.clearValue()
                                                                                                                    cancelledChipRef.current.deselectChip()
                                                                                                                    didntShowChipRef.current.deselectChip()
                                                                                                                    rejectedChipRef.current.deselectChip()
                                                                                                                }}>Completed</FilterChip>
                                                                                                                <FilterChip ref={cancelledChipRef} onSelect={() => {
                                                                                                                    setTimeout(() => setFilter(cancelledChipRef.current.getFilterChipSelected()), 100)
                                                                                                                    filterRef.current.clearValue()
                                                                                                                    completedChipRef.current.deselectChip()
                                                                                                                    didntShowChipRef.current.deselectChip()
                                                                                                                    rejectedChipRef.current.deselectChip()
                                                                                                                }}>Cancelled</FilterChip>
                                                                                                                <FilterChip ref={didntShowChipRef} onSelect={() => {
                                                                                                                    setTimeout(() => setFilter(didntShowChipRef.current.getFilterChipSelected()), 100)
                                                                                                                    filterRef.current.clearValue()
                                                                                                                    completedChipRef.current.deselectChip()
                                                                                                                    cancelledChipRef.current.deselectChip()
                                                                                                                    rejectedChipRef.current.deselectChip()
                                                                                                                }}>{`Didn't show`}</FilterChip>
                                                                                                                <FilterChip ref={rejectedChipRef} onSelect={() => {
                                                                                                                    setTimeout(() => setFilter(rejectedChipRef.current.getFilterChipSelected()), 100)
                                                                                                                    filterRef.current.clearValue()
                                                                                                                    completedChipRef.current.deselectChip()
                                                                                                                    cancelledChipRef.current.deselectChip()
                                                                                                                    didntShowChipRef.current.deselectChip()
                                                                                                                }}>{`Rejected`}</FilterChip>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    }
                                                                                                    <div className='w-64 pl-2'>
                                                                                                        <TextInput ref={filterRef} placeholder='Search customers' onChange={() => {
                                                                                                            setFilter(filterRef.current.getInputText())
                                                                                                            if (type === "upcoming") {
                                                                                                                acceptedChipRef.current.deselectChip()
                                                                                                                pendingChipRef.current.deselectChip()
                                                                                                            } else {
                                                                                                                completedChipRef.current.deselectChip()
                                                                                                                cancelledChipRef.current.deselectChip()
                                                                                                                didntShowChipRef.current.deselectChip()
                                                                                                                rejectedChipRef.current.deselectChip()
                                                                                                            }
                                                                                                        }}
                                                                                                            leadingIcon={<Icon.Search color='#d0d5dd' size={20} />}
                                                                                                            trailingIcon={
                                                                                                                <div className=' w-5 h-5 rounded-full bg-gray-100 hover:bg-gray-200 flex justify-center items-center'>
                                                                                                                    <Icon.X color='#afb5d9' size={12} />
                                                                                                                </div>}
                                                                                                            onTrailingClick={() => {
                                                                                                                setFilter("")
                                                                                                                filterRef?.current.clearValue()
                                                                                                            }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='h-full '>
                                                                                                    <AppointmentTable
                                                                                                        data={data}
                                                                                                        ref={selectedBookingIndexRef}
                                                                                                        filter={filter}
                                                                                                        type={type}
                                                                                                    />
                                                                                                </div>
                                                                                            </>

                                                                                        )
                                                                                    }
                                                                                </>
                                                                            }

                                                                        </div>


                                                                    </div>
                                                                </>}
                                                        </div>
                                                    ) : (
                                                        <>
                                                        </>
                                                    )}
                                                </div>
                                            </>


                                        </div>
                                    </div>
                                }
                            </div>
                        )}

                        {/* Appointment details desktop */}

                        <div className="hidden w-[24rem] shrink-0 px-4 pt-8 md:block bg-white mt-2 mb-2 mr-2 rounded-lg">
                            <AppointmentDetails selectedBooking={selectedBooking} activePage={type} isMobile={isMobile}
                                onCheckout={() => {
                                    handlePageUpdate(PageParts.BOOKINGS)
                                    showToast("Appointment marked as completed", "success")
                                }}
                                onDidntShow={() => {
                                    handlePageUpdate(PageParts.BOOKINGS)
                                    showToast("Appointment marked as didn't show", "success")
                                }}
                                onAccept={() => {
                                    handlePageUpdate(PageParts.BOOKINGS)
                                    showToast("Appointment accepted!", "success")
                                }}
                                onReject={() => {
                                    handlePageUpdate(PageParts.BOOKINGS)
                                    showToast("Appointment rejected", "success")
                                }}
                                onActionError={() => {
                                    handlePageUpdate(PageParts.BOOKINGS)
                                    showToast("Whoops, we couldn't do that. Reload the page and try again! If the error persists, get in touch with the Wavier Team ASAP", "error", 10000)
                                }}
                            />

                        </div>
                    </div>
                </div>

                <div>

                </div>
            </main >
            <Toaster />
        </div >
    )
}


export default AppointmentsPage
